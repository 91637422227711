
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.faq-article-item {
  $parentClass: &;

  position: relative;
  cursor: pointer;

  &__image {
    position: relative;
    height: 180px;
    margin-bottom: 16px;
    border-radius: $base-border-radius * 2;
    overflow: hidden;
    display: block;

    picture {
      position: relative;
      z-index: 2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    @include for-size(phone-portrait-down) {
      margin-bottom: 8px;
    }
  }

  &__image-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    background-position: center;
    background-size: cover;
    opacity: 0.25;
    z-index: 1;
  }

  &__additional-info {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: $color-black-op-50;
    transition: color $base-animation-time $base-animation-function;
  }

  &__title {
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
    color: $color-black;
    transition: color $base-animation-time $base-animation-function;
    @include for-size(phone-portrait-down) {
      font-size: 14px;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -$base-border-radius;
    left: -$base-border-radius;
    width: calc(100% + #{$base-border-radius * 2});
    height: calc(100% + #{$base-border-radius * 2});
    background: $color-white;
    border-radius: $base-border-radius * 2;
    pointer-events: none;
    opacity: 0;
    transition: opacity $base-animation-time $base-animation-function;
  }

  &:hover {
    &:before {
      opacity: 1;
    }

    #{$parentClass}__additional-info,
    #{$parentClass}__title {
      color: $primary-color;
    }
  }
}
