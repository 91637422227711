
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.faq-category-list {
  margin-bottom: 24px;
  @include for-size(phone-portrait-down) {
    &.tile-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      column-gap: 16px;
      row-gap: 16px
    }
  }
}
