
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.faq-article-list {
  margin-bottom: 28px;

  &__items {
    position: relative;
  }

  &__pagination {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }

  @include for-size(phone-portrait-down) {
    .tile-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      column-gap: 16px;
      row-gap: 16px
    }
  }
}
