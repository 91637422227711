
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.faq-category-page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title,
  &__sub-title {
    margin-bottom: 24px;
  }
}
