
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.faq-category-item {
  border-radius: $base-border-radius * 2;
  padding: 20px;
  background: $color-white;
  cursor: pointer;

  @include for-size(phone-portrait-down) {
    padding: 16px;
    min-height: 152px;
    &__icon {
      width: 48px;
      height: 48px;
    }
  }

  &__image {
    width: 24px;
    display: block;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 34px;
    @include for-size(phone-portrait-down) {
      flex-direction: column-reverse;
      margin-bottom: 16px;
    }
  }

  &__title {
    flex-grow: 1;
    padding-top: 4px;
    padding-right: 10px;
    color: $color-text;
    font-weight: 700;
    @include for-size(phone-portrait-down) {
      font-size: 14px;
      padding: 0;
      margin-top: 12px;
    }
  }

  &__articles-count {
    color: $color-grayscale-60;
  }

  &:hover {
    transition: box-shadow $base-animation-time
      $base-animation-function;
    box-shadow: $base-shadow-xl;
  }
}
